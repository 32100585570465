export const languageService = {
    getSelectedLanguage,
    setSelectedLanguage,
    fillInParameter
};

function getSelectedLanguage() {
    const lang = localStorage.getItem("selectedLanguage")
    if (lang===null) {
        return "de"
    }
    return lang
}

function setSelectedLanguage(lang) {
    if (lang !== "de" && lang !== "en") {
        lang = "de"
    }
    localStorage.setItem("selectedLanguage", lang)
}

function fillInParameter(text, element) {
    let result = text
    for (const [key, value] of Object.entries(element)) {
        result = result.replaceAll("${"+key+"}", value)
    }
    return result
}