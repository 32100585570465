import { createApp } from 'vue'
import App from './App.vue'

import router from "@/core/router";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, fab)

const app = createApp(App).use(router).component("font-awesome-icon", FontAwesomeIcon)

app.mount('#app')