<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap');

:root {
  --background: #E7EAF7;
  --primary: #1A6A82;
  --secondary: #cf863e;
  --third: #dc1f25;
  --success: #4CAF50;
  --danger: #C62828;
  --warning: #FFA726;
  --text-color: #ffffff;
  --secondary-text-color: #333333;
  --gray-text-color: #525252;
  --border-color: hsla(0, 0%, 54%, .1);
  --primary-container-background: rgba(255,255,255,0.90);
  --secondary-container-background: rgba(255,255,255,0.83);
}

html, body, #app {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--background);
  overflow: hidden;
  /**background-image: linear-gradient(180deg, #abe9cd 0%, #3eadcf 90%);**/
  font-family: 'Raleway', sans-serif;
  position: absolute;
}

a {
  color: unset;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
</style>
