<template>
  <div class="dashboard">
    <div class="wrapper-for-scrollbar">
      <div class="top-bar">
        <div class="profile">
          <img src="https://x4h4i2u3.rocketcdn.me/wp-content/uploads/2019/04/tinder-profilbild-schlechtes-Beispielfoto-Selfie-1-1024x542.jpg"/>
          <span>{{ getWelcomeText }}</span>
        </div>
        <div class="current-states">
          <div class="state">
            <span class="number">0</span>
            <span class="text">{{ textContent.global.statistic.upcoming[lang] }}</span>
          </div>
          <div class="state">
            <span class="number">0</span>
            <span class="text">{{ textContent.global.statistic.newMessages[lang] }}</span>
          </div>
          <div class="state">
            <span class="number">-1</span>
            <span class="text">{{ textContent.global.statistic.friends[lang] }}</span>
          </div>
        </div>
      </div>
      <div class="dashboard-content">
        <EventList id="upcoming_ev" :headline="textContent.upcoming.headline[lang]" :lang="lang" :events="upcoming"></EventList>
        <div class="calendar-and-messages">
          <div class="calendar">
            <div class="calendar-headline">
              <font-awesome-icon icon="calendar"></font-awesome-icon>
              <span class="month">{{ getCurrentCalendarMonth }} {{ calendarYear }}</span>
              <font-awesome-icon class="calendar-navigation" @click="nextMonth"
                                 icon="chevron-right"></font-awesome-icon>
              <font-awesome-icon class="calendar-navigation" @click="lastMonth" icon="chevron-left"></font-awesome-icon>
            </div>
            <table class="calendar-table">
              <tr>
                <th>{{ textContent.weekdays.mon[lang] }}</th>
                <th>{{ textContent.weekdays.tue[lang] }}</th>
                <th>{{ textContent.weekdays.wed[lang] }}</th>
                <th>{{ textContent.weekdays.thu[lang] }}</th>
                <th>{{ textContent.weekdays.fri[lang] }}</th>
                <th>{{ textContent.weekdays.sat[lang] }}</th>
                <th>{{ textContent.weekdays.sun[lang] }}</th>
              </tr>
              <tr class="calendar-row" v-for="(item, indx) in getCalendar" :key="'dashboard_calendar_row_'+indx">
                <td :class="[(date.isToday)?'is-today':'',(date.events && date.events.length > 0)?'event':'']"
                    v-for="(date, calindx) in item" :key="'dashboard_calendar_row_'+indx+'_cell_'+calindx">
                  {{ (date.active) ? date.day : '' }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {textContent} from "./dashboard.json"
import {languageService} from "@/core/services/languageService";
import EventList from "@/components/view/EventList";

export default {
  name: "Dashboard",
  components: {EventList},
  props: ["lang", "user"],
  data() {
    return {
      textContent: textContent,
      upcoming: [
        {
          thumbnail: "https://tennishead.net/wp-content/uploads/2021/04/10_Ariake_Tennis_Park_2020.jpeg",
          name: "Tennis Tunier am Waldplatz",
          date: "2021-05-21",
          time: "15:00",
          description: "Wir spielen. Du auch?",
          creator: "Frodo",
          sport: "Tennis",
          minPlayer: 2,
          maxPlayer: 4,
          currentPlayer: 3
        },
        {
          thumbnail: "https://tennishead.net/wp-content/uploads/2021/04/10_Ariake_Tennis_Park_2020.jpeg",
          name: "Tennis Tunier am Waldplatz",
          date: "2021-06-20",
          time: "13:30",
          description: "Wir spielen. Du auch?",
          creator: "Frodo",
          sport: "Tennis",
          minPlayer: 2,
          maxPlayer: 4,
          currentPlayer: 3
        },
        {
          thumbnail: "https://st.hzcdn.com/simgs/pictures/landscapes/rhino-full-basketball-court-gallery-bay-area-rhino-court-img~c8511d1705ef74bd_4-4485-1-e69b526.jpg",
          name: "Basketball NBA Niveau",
          date: "2021-11-04",
          time: "12:00",
          description: "Nur für kuhle Kids.",
          creator: "Lamda",
          sport: "Basketball",
          minPlayer: 6,
          maxPlayer: 14,
          currentPlayer: 5
        }
      ],
      calendarYear: 2021,
      calendarMonth: 4
    }
  },
  mounted() {
    const today = new Date()
    this.calendarYear = today.getFullYear()
    this.calendarMonth = today.getMonth() + 1
  },
  methods: {
    nextMonth() {
      if (this.calendarMonth > 11) {
        this.calendarMonth = 1
        this.calendarYear++;
      } else {
        this.calendarMonth++
      }
    },
    lastMonth() {
      if (this.calendarMonth <= 1) {
        this.calendarMonth = 12
        this.calendarYear--;
      } else {
        this.calendarMonth--
      }
    }
  },
  computed: {
    getWelcomeText() {
      return languageService.fillInParameter(textContent.global.welcomeText[this.lang], this.user)
    },
    getCurrentCalendarMonth() {
      return this.textContent.months[this.calendarMonth.toString()][this.lang]
    },
    getCalendar() {
      let firstDay = new Date(this.calendarYear, this.calendarMonth + 1, 1)
      let lastDay = new Date(this.calendarYear, this.calendarMonth + 2, 0)
      let weeks = []
      let startCounter = 1
      const today = new Date()
      for (let i = 1; i <= 6; i++) {
        let tmpWeekArray = []
        for (let j = 0; j < 7; j++) {
          if (i === 1 && firstDay.getDay() >= j) {
            tmpWeekArray.push({
              active: false
            })
          } else if (startCounter > lastDay.getDate()) {
            tmpWeekArray.push({
              active: false
            })
          } else {
            let istoday = true
            if (today.getFullYear() !== this.calendarYear) {
              istoday = false
            } else if (today.getMonth() + 1 !== this.calendarMonth) {
              istoday = false
            } else if (today.getDate() !== startCounter) {
              istoday = false
            }
            let events = []
            for (const event of this.upcoming) {
              const eventdate = event.date.split("-")
              if (parseInt(eventdate[0]) === this.calendarYear && parseInt(eventdate[1]) === this.calendarMonth && parseInt(eventdate[2]) === startCounter) {
                events.push(event)
              }
            }
            tmpWeekArray.push({
              active: true,
              day: startCounter++,
              isToday: istoday,
              events: events
            })
          }
        }
        weeks.push(tmpWeekArray)
      }
      return weeks
    }
  }
}
</script>
<style>
.dashboard .dashboard-content .coming-up .event .event-data .createdby span {
  display: inline-block;
  color: var(--secondary-text-color);
}
</style>
<style scoped>
.dashboard {
  position: absolute;
  overflow: hidden;
  width: 100%;
  max-width: 1500px;
  margin:0 auto;
  height: 100%;
}

.dashboard .wrapper-for-scrollbar {
  padding-right: 17px;
  overflow: auto;
  height: 100%;
  width: calc(100% + 17px);
  box-sizing: content-box;
}

.dashboard .top-bar {
  display: grid;
  grid-template-columns: 350px 1fr;
  margin-bottom: 50px;
}

.dashboard .top-bar .profile {
  height: 100%;
  width: 100%;
  text-align: center;
}

.dashboard .top-bar .profile img {
  height: 150px;
  margin: 50px 100px 10px;
  border-radius: 50%;
  width: 150px;
  object-fit: cover;
}

.dashboard .top-bar .profile span {
  font-size: 1.1em;
  border-top: 2px solid var(--border-color);
  padding-top: 5px;
}

.dashboard .top-bar .current-states {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard .top-bar .current-states .state {
  background-color: var(--primary-container-background);
  border-radius: 5px;
  width: 30%;
  height: 80px;
  padding-left: 20px;
}

.dashboard .top-bar .current-states .state:not(:first-child) {
  margin-left: 5%;
}

.dashboard .top-bar .current-states .state .number {
  font-size: 36px;
  display: block;
  padding-bottom: 5px;
}

.dashboard .dashboard-content {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr 450px;
  grid-gap: 25px;
}

.dashboard .dashboard-content .calendar-and-messages {
  width: 100%;
}

.dashboard .dashboard-content .calendar-and-messages .calendar {
  background-color: var(--secondary-container-background);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin: 10px 0;
  padding: 25px;
}

.dashboard .dashboard-content .calendar-and-messages .calendar .calendar-headline {
  font-size: 1.4em;
  color: var(--primary);
  user-select: none;
}

.dashboard .dashboard-content .calendar-and-messages .calendar .calendar-headline .month {
  margin-left: 25px;
}

.dashboard .dashboard-content .calendar-and-messages .calendar .calendar-headline .calendar-navigation {
  float: right;
  color: var(--secondary-text-color);
  margin: 0 10px;
  cursor: pointer;
}

.dashboard .dashboard-content .calendar-and-messages .calendar .calendar-table {
  width: 100%;
  margin-top: 25px;
  user-select: none;
  color: var(--gray-text-color);
}

.dashboard .dashboard-content .calendar-and-messages .calendar .calendar-table .calendar-row {
  text-align: center;
  margin-top: 10px;
}

.dashboard .dashboard-content .calendar-and-messages .calendar .calendar-table .calendar-row td {
  padding: 10px 0;
  border-radius: 5px;
}

.dashboard .dashboard-content .calendar-and-messages .calendar .calendar-table .calendar-row td.is-today {
  background-color: var(--primary);
  color: var(--text-color)
}

.dashboard .dashboard-content .calendar-and-messages .calendar .calendar-table .calendar-row td.event {
  background-color: var(--success);
  color: var(--text-color)
}

.dashboard .dashboard-content .calendar-and-messages .calendar .calendar-table .calendar-row td.is-today {
  background-color: var(--primary);
  color: var(--text-color)
}

.dashboard .dashboard-content .calendar-and-messages .calendar .calendar-table .calendar-row td.is-today.event {
  background-image: -webkit-linear-gradient(30deg, var(--primary) 50%, var(--success) 50%);
  color: var(--text-color)
}

@media only screen and (max-width: 1550px) {
  .dashboard .top-bar {
    grid-template-columns: 1fr;
  }

  .dashboard .top-bar .profile {
    margin-bottom: 25px;
  }

  .dashboard .top-bar .profile img {
    display: none;
  }

  .dashboard .top-bar .profile span {
    font-size: 2em;
  }

  .dashboard .dashboard-content {
    grid-template-columns: 600px 1fr;
  }

  .dashboard .dashboard-content .coming-up {
    font-size: 0.8em;
  }

  .dashboard .dashboard-content .coming-up .event {
    grid-template-columns: 1fr;
    padding: 0 25px;
  }

  .dashboard .dashboard-content .coming-up .event img {
    display: none;
  }
}

@media only screen and (max-width: 1050px) {
  .dashboard .dashboard-content {
    grid-template-columns: 1fr;
  }
}
</style>