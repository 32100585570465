<template>
  <div class="load">
    <div class="ring-2">
      <div class="ball-holder">
        <div class="ball"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading"
}
</script>

<style scoped>
.load {
  width: 22px;
  height: 22px;
  padding: 5px;
}
.ring-2 {
  position: relative;
  width: 22px;
  height: 22px;
  margin: 0 auto;
  border: 2px solid var(--primary);
  border-radius: 100%;
}

.ball-holder {
  position: absolute;
  width: 6px;
  height: 22px;
  left: 7px;
  top: 0;
}

.ball {
  position: absolute;
  top: -6px;
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #4282b3;
}

.load .ball-holder {
  animation: loadingE 1.3s linear infinite;
}

@keyframes loadingE {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>