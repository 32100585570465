<template>
  <div class="create-event">
    <div class="inner-content">
      <div class="wrapper-for-scrollbar">
        <div class="info-box">
          <div class="inner-list">
            <div v-if="currentStep===1">
              <div class="form-input form-input-image">
                <img class=""
                     src="https://st.hzcdn.com/simgs/pictures/landscapes/rhino-full-basketball-court-gallery-bay-area-rhino-court-img~c8511d1705ef74bd_4-4485-1-e69b526.jpg"
                     alt="profile"/>
                <font-awesome-icon icon="sync-alt"></font-awesome-icon>
              </div>
              <div class="tag-block">
                <div class="form-headline">{{ textContent.info.sport[lang] }}</div>
                <div class="input-tag-auto" @click="opener.sport=true" v-for="(item, indx) in availableSportsFiltered"
                     :key="'create-event-sport-'+indx+'-'+item">
                  <label :for="'sport-input-'+indx+'-'+item">
                    <div :class="['inner-label', (item===sport)?'selected':'']">{{
                        textContent.sports[item].sport[lang]
                      }}
                    </div>
                  </label>
                  <input type="radio" v-model="sport" name="sport" :id="'sport-input-'+indx+'-'+item" :value="item">
                </div>
              </div>
              <div class="tag-block">
                <div class="form-headline">{{ textContent.info.subcategory[lang] }}</div>
                <template v-if="sport.length > 0">
                  <div class="input-tag-auto" @click="opener.subcategory=true"
                       v-for="(item, key) in availableSubCategoriesFiltered"
                       :key="'create-event-sport-subtype-'+key">
                    <label :for="'sport-sub-type-input-'+key">
                      <div :class="['inner-label', (key===subcategory)?'selected':'']">
                        {{ textContent.sports[sport].subtypes[key][lang] }}
                      </div>
                    </label>
                    <input type="radio" v-model="subcategory" name="subcategory" :id="'sport-sub-type-input-'+key"
                           :value="key">
                  </div>
                </template>
                <div class="outstanding" v-else>
                  <font-awesome-icon icon="info"></font-awesome-icon>
                  {{ textContent.infoMessages.selectSport[lang] }}
                </div>
              </div>
              <hr>
              <div class="form-input small-input-300">
                <label for="date_input">{{ textContent.info.date[lang] }}</label>
                <input type="date" id="date_input" v-model="date"/>
              </div>
              <div class="tag-block">
                <div class="form-headline">{{ textContent.info.startTime[lang] }}</div>
                <template v-if="date.length > 0">
                  <div class="input-tag-100" @click="opener.timeStart=true"
                       v-for="(item, indx) in availableTimesFiltered"
                       :key="'create-event-available-time-'+indx+'-'+item">
                    <label :for="'available-time-input-'+indx+'-'+item">
                      <div :class="['inner-label', (item===timeStart)?'selected':'']">{{ item }}</div>
                    </label>
                    <input type="radio" v-model="timeStart" name="time-start"
                           :id="'available-time-input-'+indx+'-'+item"
                           :value="item">
                  </div>
                </template>
                <div class="outstanding" v-else>
                  <font-awesome-icon icon="info"></font-awesome-icon>
                  {{ textContent.infoMessages.selectDate[lang] }}
                </div>
              </div>
              <div class="tag-block">
                <div class="form-headline">{{ textContent.info.duration[lang] }}</div>
                <template v-if="timeStart.length > 0">
                  <div class="input-tag-100" @click="opener.duration=true"
                       v-for="(item, indx) in availableDurationsFiltered"
                       :key="'create-event-duration-time-'+indx+'-'+item">
                    <label :for="'duration-time-input-'+indx+'-'+item">
                      <div :class="['inner-label', (item===duration)?'selected':'']">{{ item }} Min.</div>
                    </label>
                    <input type="radio" v-model="duration" name="duration" :id="'duration-time-input-'+indx+'-'+item"
                           :value="item">
                  </div>
                </template>
                <div class="outstanding" v-else-if="date.length > 0">
                  <font-awesome-icon icon="info"></font-awesome-icon>
                  {{ textContent.infoMessages.selectStartTime[lang] }}
                </div>
                <div class="outstanding" v-else>
                  <font-awesome-icon icon="info"></font-awesome-icon>
                  {{ textContent.infoMessages.selectDate[lang] }}
                </div>
              </div>
              <hr>
              <div class="form-input small-input-300">
                <label for="end_date_input">{{ textContent.info.endDate[lang] }}</label>
                <input type="date" id="end_date_input" :max="date" v-model="searchEndDate"/>
              </div>
              <div class="tag-block">
                <div class="form-headline">{{ textContent.info.endTime[lang] }}</div>
                <template v-if="searchEndDate.length > 0">
                  <div class="input-tag-100" @click="opener.endTime=true" v-for="(item, indx) in endSearchTimesFiltered"
                       :key="'create-event-available-end-time-'+indx+'-'+item">
                    <label :for="'available-end-time-input-'+indx+'-'+item">
                      <div :class="['inner-label', (item===searchEndTime)?'selected':'']">{{ item }}</div>
                    </label>
                    <input type="radio" v-model="searchEndTime" name="time-start"
                           :id="'available-end-time-input-'+indx+'-'+item"
                           :value="item">
                  </div>
                </template>
                <div class="outstanding" v-else>
                  <font-awesome-icon icon="info"></font-awesome-icon>
                  {{ textContent.infoMessages.selectEndDate[lang] }}
                </div>
              </div>
              <hr>
              <div :class="['form-input','next-button',(duration > 0 && searchEndTime.length > 0)?'':'disabled']">
                <button class="connect-button" @click="currentStep=(duration > 0 && searchEndTime.length > 0)?2:1">
                  <font-awesome-icon icon="arrow-right"></font-awesome-icon>
                  {{ textContent.values.next[lang] }}
                </button>
              </div>
            </div>
            <div v-else-if="currentStep===2">
              <div class="form-input form-input-image">
                <img class=""
                     src="https://st.hzcdn.com/simgs/pictures/landscapes/rhino-full-basketball-court-gallery-bay-area-rhino-court-img~c8511d1705ef74bd_4-4485-1-e69b526.jpg"
                     alt="profile"/>
                <font-awesome-icon icon="sync-alt"></font-awesome-icon>
              </div>
              <div class="form-input small-input-300">
                <label for="name_input">{{ textContent.info.name[lang] }}</label>
                <input type="text" id="name_input" v-model="name"/>
              </div>
              <div class="form-input">
                <label for="description_input">{{ textContent.info.description[lang] }}</label>
                <textarea rows="5" id="description_input" v-model="description"/>
              </div>
              <div :class="['form-input','next-button']">
                <button class="connect-button back" @click="currentStep=1">
                  <font-awesome-icon icon="arrow-left"></font-awesome-icon>
                  {{ textContent.values.back[lang] }}
                </button>
                <button :class="['connect-button',(name.length > 0 && description.length > 0)?'':'disabled']"
                        @click="currentStep=(name.length > 0 && description.length > 0)?3:2">
                  <font-awesome-icon icon="arrow-right"></font-awesome-icon>
                  {{ textContent.values.next[lang] }}
                </button>
              </div>
            </div>
            <div v-else-if="currentStep===3">
              <div class="tag-block">
                <div class="form-headline">{{ textContent.info.playerCountMin[lang] }}</div>
                <div class="input-tag-100" @click="opener.playerCountMin=true"
                     v-for="(item, indx) in availablePlayerCountMinFiltered"
                     :key="'create-event-player-count-min-time-'+indx+'-'+item">
                  <label :for="'player-count-min-input-'+indx+'-'+item">
                    <div :class="['inner-label', (item===playerCountMin)?'selected':'']">{{ item }}</div>
                  </label>
                  <input type="radio" v-model="playerCountMin" name="duration"
                         :id="'player-count-min-input-'+indx+'-'+item"
                         :value="item">
                </div>
              </div>
              <div class="tag-block">
                <div class="form-headline">{{ textContent.info.playerCountMax[lang] }}</div>
                <template v-if="playerCountMin > 0">
                  <div class="input-tag-100" @click="opener.playerCountMax=true"
                       v-for="(item, indx) in availablePlayerCountMaxFiltered"
                       :key="'create-event-player-count-max-time-'+indx+'-'+item">
                    <label :for="'player-count-max-input-'+indx+'-'+item">
                      <div :class="['inner-label', (item===playerCountMax)?'selected':'']">{{ item }}</div>
                    </label>
                    <input type="radio" v-model="playerCountMax" name="duration"
                           :id="'player-count-max-input-'+indx+'-'+item"
                           :value="item">
                  </div>
                </template>
                <div class="outstanding" v-else>
                  <font-awesome-icon icon="info"></font-awesome-icon>
                  {{ textContent.infoMessages.selectPlayerCountMin[lang] }}
                </div>
              </div>
              <hr>
              <div class="tag-block">
                <div class="form-headline">{{ textContent.info.difficulties[lang] }}</div>
                <div class="input-tag-auto" @click="opener.difficulties=true"
                     v-for="(item, key) in availableDifficultiesFiltered"
                     :key="'create-event-difficulty-'+key">
                  <label :for="'difficulty-input-'+key">
                    <div :class="['inner-label', (key===difficulty)?'selected':'']">
                      {{ textContent.difficulties[key][lang] }}
                    </div>
                  </label>
                  <input type="radio" v-model="difficulty" name="difficulty" :id="'difficulty-input-'+key"
                         :value="key">
                </div>
              </div>
              <div class="tag-block">
                <div class="form-headline">{{ textContent.info.public[lang] }}</div>
                <div class="input-tag-auto">
                  <label :for="'public-input-true'">
                    <div :class="['inner-label', (isPublic)?'selected':'']">
                      {{ textContent.values.true[lang] }}
                    </div>
                  </label>
                  <input type="radio" v-model="isPublic" name="public" :value="true" :id="'public-input-true'">
                </div>
                <div class="input-tag-auto">
                  <label :for="'public-input-false'">
                    <div :class="['inner-label', (!isPublic)?'selected':'']">
                      {{ textContent.values.false[lang] }}
                    </div>
                  </label>
                  <input type="radio" v-model="isPublic" name="public" :value="false" :id="'public-input-false'">
                </div>
              </div>
              <div :class="['form-input','next-button']">
                <button class="connect-button back" @click="currentStep=2">
                  <font-awesome-icon icon="arrow-left"></font-awesome-icon>
                  {{ textContent.values.back[lang] }}
                </button>
                <button
                    :class="['connect-button',(difficulty.length > 0 && playerCountMin > 0 && playerCountMax > 0)?'':'disabled']">
                  <font-awesome-icon icon="arrow-right"></font-awesome-icon>
                  {{ textContent.values.createEvent[lang] }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inner-content">
      <div class="wrapper-for-scrollbar">
        <div class="summary-box">
          <h1>{{ textContent.summary.summary[lang] }}</h1>
          <hr>
          <div class="sub-summary">
            <span class="title">{{ textContent.summary.sport[lang] }}</span>
            <span class="content" v-if="sport.length > 0">{{ textContent.sports[sport].sport[lang] }}</span>
            <span class="content" v-else><Loading></Loading></span>
          </div>
          <div class="sub-summary">
            <span class="title">{{ textContent.summary.subcategory[lang] }}</span>
            <span class="content" v-if="subcategory.length > 0">{{
                textContent.sports[sport].subtypes[subcategory][lang]
              }}</span>
            <span class="content" v-else><Loading></Loading></span>
          </div>
          <div class="sub-summary">
            <span class="title">{{ textContent.summary.date[lang] }}</span>
            <span class="content" v-if="date.length > 0">{{ dateFormatted }}</span>
            <span class="content" v-else><Loading></Loading></span>
          </div>
          <div class="sub-summary">
            <span class="title">{{ textContent.summary.time[lang] }}</span>
            <span class="content" v-if="timeStart.length > 0">{{ timeStartFormatted }} - {{ timeEnd }}</span>
            <span class="content" v-else><Loading></Loading></span>
          </div>
          <div class="sub-summary">
            <span class="title">{{ textContent.summary.searchEnd[lang] }}</span>
            <span class="content" v-if="searchEndDate.length > 0 && searchEndTime.length > 0">{{
                searchEndFormatted
              }}</span>
            <span class="content" v-else><Loading></Loading></span>
          </div>
          <hr>
          <div class="sub-summary">
            <span class="title">{{ textContent.summary.name[lang] }}</span>
            <span class="content" v-if="name.length > 0">{{ name }}</span>
            <span class="content" v-else><Loading></Loading></span>
          </div>
          <div class="sub-summary">
            <span class="title">{{ textContent.summary.description[lang] }}</span>
            <span class="content" v-if="description.length > 0">{{ description }}</span>
            <span class="content" v-else><Loading></Loading></span>
          </div>
          <hr>
          <div class="sub-summary">
            <span class="title">{{ textContent.summary.playerCount[lang] }}</span>
            <span class="content" v-if="playerCountMin > 0 && playerCountMax > 0">{{ playerCountMin }} - {{
                playerCountMax
              }}</span>
            <span class="content" v-else><Loading></Loading></span>
          </div>
          <div class="sub-summary">
            <span class="title">{{ textContent.summary.difficulty[lang] }}</span>
            <span class="content" v-if="difficulty.length>0">{{ textContent.difficulties[difficulty][lang] }}</span>
            <span class="content" v-else><Loading></Loading></span>
          </div>
          <div class="sub-summary">
            <span class="title">{{ textContent.summary.isPublic[lang] }}</span>
            <span class="content">{{ textContent.values[isPublic.toString()][lang] }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {createEventContent} from "@/components/event/event.json";
import Loading from "@/components/view/Loading";

export default {
  name: "CreateEvent",
  components: {Loading},
  props: ["lang"],
  data() {
    return {
      textContent: createEventContent,
      sport: "",
      subcategory: "",
      date: "",
      timeStart: "",
      duration: 0,
      searchEndDate: "",
      searchEndTime: "",
      name: "",
      description: "",
      playerCountMin: 0,
      playerCountMax: 0,
      difficulty: "",
      isPublic: true,
      availableSports: ["basketball"],
      availableTimes: ["11:00", "11:30", "12:00", "15:00"],
      availableDurations: [30, 45, 60, 90, 120],
      endSearchTimes: ["09:00", "12:00", "15:00", "18:00", "21:00"],
      opener: {
        sport: true,
        subcategory: false,
        timeStart: false,
        duration: false,
        endTime: false,
        playerCountMin: true,
        playerCountMax: false,
        difficulties: true
      },
      currentStep: 1
    }
  },
  watch: {
    sport() {
      this.opener.sport = false
      this.opener.subcategory = true
    },
    subcategory() {
      this.opener.subcategory = false
    },
    date() {
      this.opener.timeStart = true
    },
    timeStart() {
      this.opener.timeStart = false
      this.opener.duration = true
    },
    duration() {
      this.opener.duration = false
    },
    searchEndDate() {
      this.opener.endTime = true
    },
    searchEndTime() {
      this.opener.endTime = false
    },
    playerCountMin() {
      if (this.playerCountMax < this.playerCountMin) {
        this.playerCountMax = this.playerCountMin
      }
      this.opener.playerCountMin = false
      this.opener.playerCountMax = true
      let self = this
      setTimeout(function () {
        self.opener.playerCountMax = true
      }, 100)
    },
    playerCountMax() {
      this.opener.playerCountMax = false
    },
    difficulty() {
      this.opener.difficulties = false
    }
  },
  computed: {
    availableSportsFiltered() {
      if (this.opener.sport) {
        return this.availableSports;
      }
      return [this.sport];
    },
    availableTimesFiltered() {
      if (this.opener.timeStart) {
        return this.availableTimes;
      }
      return [this.timeStart];
    },
    availableDurationsFiltered() {
      if (this.opener.duration) {
        return this.availableDurations;
      }
      return [this.duration];
    },
    endSearchTimesFiltered() {
      if (this.opener.endTime) {
        return this.endSearchTimes;
      }
      return [this.searchEndTime]
    },
    availableSubCategoriesFiltered() {
      if (this.opener.subcategory) {
        return this.textContent.sports[this.sport].subtypes;
      }
      let result = {};
      for (const subcategory in this.textContent.sports[this.sport].subtypes) {
        if (subcategory === this.subcategory) {
          result[subcategory] = this.textContent.sports[this.sport].subtypes
          break;
        }
      }
      return result
    },
    availablePlayerCountMinFiltered() {
      if (this.opener.playerCountMin) {
        let result = []
        for (let i = 1; i <= 16; i++) {
          result.push(i)
        }
        return result;
      }
      return [this.playerCountMin]
    },
    availablePlayerCountMaxFiltered() {
      if (this.opener.playerCountMax) {
        let result = []
        for (let i = 0; i <= 16; i++) {
          result.push(i + this.playerCountMin)
        }
        return result;
      }
      return [this.playerCountMax]
    },
    availableDifficultiesFiltered() {
      if (this.opener.difficulties) {
        return this.textContent.difficulties;
      }
      let result = {};
      result[this.difficulty] = this.textContent.difficulties[this.difficulty]
      return result
    },
    dateFormatted() {
      let date = new Date()
      const days = this.date.split("-")
      date.setFullYear(parseInt(days[0]))
      date.setMonth(parseInt(days[1]))
      date.setDate(parseInt(days[2]))
      let resultStr = ""
      if (date.getDate() < 10) {
        resultStr += "0"
      }
      resultStr += date.getDate() + "."
      if (date.getMonth() < 10) {
        resultStr += "0"
      }
      resultStr += date.getMonth() + "."
      resultStr += date.getFullYear()
      return resultStr
    },
    searchEndFormatted() {
      let date = new Date()
      const time = this.searchEndTime.split(":")
      const days = this.searchEndDate.split("-")
      date.setFullYear(parseInt(days[0]))
      date.setMonth(parseInt(days[1]))
      date.setDate(parseInt(days[2]))
      date.setHours(parseInt(time[0]))
      date.setMinutes(parseInt(time[1]))
      date.setSeconds(0)
      let resultStr = ""
      if (date.getDate() < 10) {
        resultStr += "0"
      }
      resultStr += date.getDate() + "."
      if (date.getMonth() < 10) {
        resultStr += "0"
      }
      resultStr += date.getMonth() + "."
      resultStr += date.getFullYear() + " "
      if (date.getHours() < 10) {
        resultStr += "0"
      }
      resultStr += date.getHours() + ":"
      if (date.getMinutes() < 10) {
        resultStr += "0"
      }
      resultStr += date.getMinutes()
      return resultStr
    },
    timeStartFormatted() {
      let date = new Date()
      const time = this.timeStart.split(":")
      date.setHours(parseInt(time[0]))
      date.setMinutes(parseInt(time[1]))
      date.setSeconds(0)
      let resultStr = ""
      if (date.getHours() < 10) {
        resultStr += "0"
      }
      resultStr += date.getHours() + ":"
      if (date.getMinutes() < 10) {
        resultStr += "0"
      }
      resultStr += date.getMinutes()
      return resultStr
    },
    timeEnd() {
      let date = new Date()
      const time = this.timeStart.split(":")
      date.setHours(parseInt(time[0]))
      date.setMinutes(parseInt(time[1]))
      date.setSeconds(0)
      let endTime = new Date(date.getTime() + this.duration * 60000);
      let resultStr = ""
      if (endTime.getHours() < 10) {
        resultStr += "0"
      }
      resultStr += endTime.getHours() + ":"
      if (endTime.getMinutes() < 10) {
        resultStr += "0"
      }
      resultStr += endTime.getMinutes()
      return resultStr
    }
  }
}
</script>

<style scoped>
.create-event {
  display: grid;
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
  grid-template-columns: 1fr 400px;
  grid-column-gap: 30px;
}

.create-event .inner-content {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.create-event .inner-content .wrapper-for-scrollbar {
  padding-right: 17px;
  overflow: auto;
  overflow-y: visible;
  height: 100%;
  width: calc(100%);
  box-sizing: content-box;
}

.info-box {
  background: var(--primary-container-background);
  padding: 20px;
  border-radius: 5px;
  margin: 0 0 20px 0;
}

.info-box .tag-block {
  display: inline-block;
  margin: 10px 0;
  width: 100%;
}

.info-box .tag-block .form-headline {
  color: var(--gray-text-color);
  margin-left: 10px;
  margin-bottom: 10px;
  font-size: 0.9em;
}

.info-box .outstanding {
  padding-left: 10px;
  margin: 10px 0;
  color: var(--primary);
}

.info-box .outstanding svg {
  border: 1px solid var(--primary);
  margin-right: 10px;
  width: 16px;
  height: 16px;
  padding: 2px;
  border-radius: 50%;
}

.info-box .input-tag-100 {
  float: left;
  width: 100px;
  height: 40px;
  margin: 10px 10px;
}

.info-box .input-tag-auto {
  float: left;
  width: auto;
  height: 40px;
  margin: 10px 10px;
}

.info-box .inner-label {
  font-size: 1.2em;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  cursor: pointer;
  background: var(--primary);
  color: var(--text-color);
  text-align: center;
  padding: 5px;
}

.info-box .input-tag-auto .inner-label {
  padding: 6px 15px;
}

.info-box .inner-label.selected {
  border: 1px solid var(--success);
  color: var(--success);
  background: transparent;
}

.info-box .input-tag-100 input, .info-box .input-tag-auto input {
  display: none;
}

.next-button {
  text-align: right;
}

.next-button button {
  color: var(--success);
}

.next-button.disabled button, .next-button button.disabled {
  color: var(--gray-text-color);
  cursor: not-allowed;
}

.summary-box {
  background: var(--primary-container-background);
  padding: 20px;
  text-align: center;
  border-radius: 5px;
}

.summary-box .sub-summary {
  width: 100%;
  text-align: left;
  margin: 20px 0;
}

.summary-box .sub-summary .title {
  font-size: 1em;
  color: var(--third);
  display: block;
}

.summary-box .sub-summary .content {
  font-size: 1.2em;
}
</style>