<template>
  <div class="settings-container">
    <div class="inner-content">
      <div class="wrapper-for-scrollbar">
        <!--
        <div class="block" id="profile">
            <div class="block-inner-left">
                <h1>Öffentliches Profil</h1>
                <span>Beschreibe dich selbst und verrate deinen Mitspielern mehr über dich</span>
            </div>
            <div class="block-inner-right">
                <div class="two-columns">
                    <div class="form-input form-input-image">
                        <label>Profilbild</label>
                        <img class="" src="https://gitea.weers-intern.de/avatars/af8d6fb755935e978f508906c0880c52" alt="profile"/>
                        <i class="fas fa-sync-alt"></i>
                    </div>
                    <div>
                        <div class="form-input">
                            <label for="display_name_input">Anzeigename</label>
                            <input type="text" id="display_name_input" value="Bjarne"/>
                        </div>
                        <div class="form-input manual-margin">
                            <label for="favorite_sport_input">Lieblingssport</label>
                            <input type="text" id="favorite_sport_input" value="Basketball"/>
                        </div>
                    </div>
                </div>
                <div class="form-input">
                    <label for="description_you_input">Beschreibung von dir</label>
                    <input type="text" id="description_you_input" value="Ich bin ein ganz kuhler Junge"/>
                </div>
            </div>
        </div>
        -->
        <div class="block" id="friendcode">
          <div class="block-inner-left">
            <h1>{{ textContent.friendCode.headline[lang] }}</h1>
            <span>{{ textContent.friendCode.text[lang] }}</span>
            <div class="message" v-if="textContent.friendCode.message">
              <div class="info-container bottom">
                <font-awesome-icon :icon="textContent.friendCode.message.icon"></font-awesome-icon>
                <span>{{ textContent.friendCode.message.text[lang] }}.</span>
              </div>
            </div>
          </div>
          <div class="block-inner-right">
            <div class="form-input">
              <label for="friendcode_input">{{ textContent.friendCode.form.friendCode[lang] }}</label>
              <input type="text" id="friendcode_input" value="Friendcode#1337"/>
            </div>
          </div>
        </div>
        <div class="block" id="useraccess">
          <div class="block-inner-left">
            <h1>{{ textContent.useraccess.headline[lang] }}</h1>
            <span>{{ textContent.useraccess.text[lang] }}</span>
            <div class="message" v-if="textContent.useraccess.message">
              <div class="info-container bottom">
                <font-awesome-icon :icon="textContent.useraccess.message.icon"></font-awesome-icon>
                <span>{{ textContent.useraccess.message.text[lang] }}.</span>
              </div>
            </div>
          </div>
          <div class="block-inner-right">
            <div class="form-input">
              <label for="username_input">{{ textContent.useraccess.form.username[lang] }}</label>
              <input type="text" id="username_input" readonly value="xxxHentaiHunterxxx"/>
            </div>
            <div class="form-input">
              <label for="password_old_input">{{ textContent.useraccess.form.currentPassword[lang] }}</label>
              <input type="password" id="password_old_input" value=""/>
            </div>
            <div class="form-input">
              <label for="password_input">{{ textContent.useraccess.form.newPassword1[lang] }}</label>
              <input type="password" id="password_input" value=""/>
            </div>
            <div class="form-input">
              <label for="password_repeat_input">{{ textContent.useraccess.form.newPassword2[lang] }}</label>
              <input type="password" id="password_repeat_input" value=""/>
            </div>
          </div>
        </div>
        <div class="block" id="information">
          <div class="block-inner-left">
            <h1>{{ textContent.information.headline[lang] }}</h1>
            <span>{{ textContent.information.text[lang] }}</span>
            <div class="message" v-if="textContent.information.message">
              <div class="info-container bottom">
                <font-awesome-icon :icon="textContent.information.message.icon"></font-awesome-icon>
                <span>{{ textContent.information.message.text[lang] }}.</span>
              </div>
            </div>
          </div>
          <div class="block-inner-right">
            <div class="two-columns">
              <div class="form-input">
                <label for="firstname_input">{{ textContent.information.form.firstname[lang] }}</label>
                <input type="text" id="firstname_input" value="Max"/>
              </div>
              <div class="form-input">
                <label for="lastname_input">{{ textContent.information.form.lastname[lang] }}</label>
                <input type="text" id="lastname_input" value="Mustermann"/>
              </div>
            </div>
            <div class="form-input">
              <label for="email_input">{{ textContent.information.form.email[lang] }}</label>
              <input type="email" id="email_input" value="noreply@spoard.de"/>
            </div>
            <div class="form-input">
              <label for="birthdate_input">{{ textContent.information.form.birthdate[lang] }}</label>
              <input type="date" id="birthdate_input" value="1990-04-01"/>
            </div>
          </div>
        </div>
        <div class="block" id="invoice">
          <div class="block-inner-left">
            <h1>{{ textContent.invoice.headline[lang] }}</h1>
            <span>{{ textContent.invoice.text[lang] }}</span>
            <div class="message" v-if="textContent.invoice.message">
              <div class="info-container bottom">
                <font-awesome-icon :icon="textContent.invoice.message.icon"></font-awesome-icon>
                <span>{{ textContent.invoice.message.text[lang] }}.</span>
              </div>
            </div>
          </div>
          <div class="block-inner-right">
            <div class="two-columns">
              <div class="form-input">
                <label for="invoice_firstname_input">{{ textContent.invoice.form.firstname[lang] }}</label>
                <input type="text" id="invoice_firstname_input" value="Max"/>
              </div>
              <div class="form-input">
                <label for="invoice_lastname_input">{{ textContent.invoice.form.lastname[lang] }}</label>
                <input type="text" id="invoice_lastname_input" value="Mustermann"/>
              </div>
            </div>
            <div class="form-input">
              <label for="email_input">{{ textContent.invoice.form.address[lang] }}</label>
              <input type="text" id="invoice_address_input" value="Teststraße 12"/>
            </div>
            <div class="two-columns">
              <div class="form-input">
                <label for="invoice_zip_input">{{ textContent.invoice.form.zip[lang] }}</label>
                <input type="text" id="invoice_zip_input" value="48155"/>
              </div>
              <div class="form-input">
                <label for="invoice_city_input">{{ textContent.invoice.form.city[lang] }}</label>
                <input type="text" id="invoice_city_input" value="Münster"/>
              </div>
            </div>
          </div>
        </div>
        <div class="block" id="connected">
          <div class="block-inner-left">
            <h1>{{ textContent.connected.headline[lang] }}</h1>
            <span>{{ textContent.connected.text[lang] }}</span>
            <div class="message" v-if="textContent.connected.message">
              <div class="info-container bottom">
                <font-awesome-icon :icon="textContent.connected.message.icon"></font-awesome-icon>
                <span>{{ textContent.connected.message.text[lang] }}.</span>
              </div>
            </div>
          </div>
          <div class="block-inner-right">
            <div class="form-input">
              <button class="connect-button twitter">
                <font-awesome-icon :icon="['fab','twitter']"></font-awesome-icon>
                 {{ textContent.connected.form.twitter[lang] }}
              </button>
            </div>
            <div class="form-input">
              <button class="connect-button facebook">
                <font-awesome-icon :icon="['fab','facebook']"></font-awesome-icon>
                {{ textContent.connected.form.facebook[lang] }}
              </button>
            </div>
            <div class="form-input">
              <button class="connect-button google">
                <font-awesome-icon :icon="['fab','google']"></font-awesome-icon>
                {{ textContent.connected.form.google[lang] }}
              </button>
            </div>
            <div class="form-input">
              <button class="connect-button twitch">
                <font-awesome-icon :icon="['fab','twitch']"></font-awesome-icon>
                {{ textContent.connected.form.twitch[lang] }}
              </button>
            </div>
          </div>
        </div>
        <div class="footer">
          © Spoard 2021 <br>
        </div>
      </div>
    </div>
    <div class="sidebar">
      <h1>{{ textContent.global.headline[lang] }}</h1>
      <ul class="sidebar-menu settings-sidebar">
        <li class="with-icon" @click="scrollIntoElement('friendcode')" data-ref="friendcode">
          <font-awesome-icon icon="heart"/>
          {{ textContent.friendCode.headline[lang] }}
        </li>
        <li class="with-icon" @click="scrollIntoElement('useraccess')" data-ref="useraccess">
          <font-awesome-icon icon="key"/>
          {{ textContent.useraccess.headline[lang] }}
        </li>
        <li class="with-icon" @click="scrollIntoElement('information')" data-ref="information">
          <font-awesome-icon icon="users-cog"/>
          {{ textContent.information.headline[lang] }}
        </li>
        <li class="with-icon" @click="scrollIntoElement('invoice')" data-ref="invoice">
          <font-awesome-icon icon="money-check-alt"/>
          {{ textContent.invoice.headline[lang] }}
        </li>
        <li class="with-icon" @click="scrollIntoElement('connected')" data-ref="connected">
          <font-awesome-icon icon="link"/>
          {{ textContent.connected.headline[lang] }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {textContent} from "./settings.json"

export default {
  name: "Settings",
  props: ["lang"],
  data() {
    return {
      visible: {},
      sidebarMenus: [],
      textContent: textContent
    }
  },
  methods: {
    scrollIntoElement(elementid) {
      document.getElementById(elementid).scrollIntoView()
    },
    renderLinkMarks() {
      let found = false
      for (const sidebarElement of this.sidebarMenus) {
        const element = sidebarElement
        if (found) {
          element.classList.remove("active")
          continue
        }
        let linkto = element.getAttribute("data-ref")
        if (this.visible[linkto]) {
          found = true
          element.classList.add("active")
        } else {
          element.classList.remove("active")
        }
      }
    }
  },
  mounted() {
    this.sidebarMenus = document.getElementsByClassName("settings-sidebar")[0].children
    let self = this
    const observer = new IntersectionObserver(function (entries) {
      for (const entry of entries) {
        const targetid = entry.target.id
        self.visible[targetid] = entry.isIntersecting
      }
      self.renderLinkMarks()
    }, {threshold: [0]});

    const blocks = document.getElementsByClassName("block")
    for (const block of blocks) {
      observer.observe(block);
    }
  }
}
</script>

<style scoped>
.settings-container {
  display: grid;
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
  grid-column-gap: 40px;
  grid-template-columns: 1fr 400px;
}

.settings-container .inner-content {
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.settings-container .inner-content .wrapper-for-scrollbar {
  padding-right: 17px;
  overflow: auto;
  height: 100%;
  width: calc(100% + 17px);
  box-sizing: content-box;
}

.settings-container .inner-content .block {
  color: var(--secondary-text-color);
  display: grid;
  font-size: 0.9em;
  border-radius: 5px;
  margin-bottom: 30px;
  grid-template-columns: repeat(2, 1fr);
}

.settings-container .inner-content .block h1 {
  margin-top: 0;
}

.settings-container .inner-content .block .block-inner-right {
  background: var(--primary-container-background);
  border-radius: 0 5px 5px 0;
  padding: 40px;
}

.settings-container .inner-content .block .block-inner-left {
  background: var(--secondary-container-background);
  border-radius: 5px 0 0 5px;
  padding: 40px;
}

.settings-container .inner-content .block .block-inner-left > span {
  color: var(--secondary-text-color);
}

.settings-container .inner-content .block .block-inner-left .message {
  width: 100%;
  height: 100px;
  position: relative;
  bottom: 0;
  color: var(--secondary-text-color);
}

@media only screen and (max-width: 1300px) {
  .settings-container {
    font-size: 0.7em;
    grid-template-columns: 1fr 250px;
  }
}

@media only screen and (max-width: 1000px) {
  .settings-container {
    grid-template-columns: 1fr;
  }

  .settings-container .sidebar {
    display: none;
  }
}

@media only screen and (max-width: 650px) {
  .settings-container .inner-content .block {
    grid-template-columns: 1fr;
  }

  .settings-container .inner-content .block .block-inner-right {
    border-radius: 0 0 5px 5px;
  }

  .settings-container .inner-content .block .block-inner-left {
    border-radius: 5px 5px 0 0;
  }
}

.settings-container .inner-content .footer {
  height: 800px;
  text-align: center;
}

.settings-container .inner-content .footer a {
  width: 100%;
}

.info-container {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.info-container.bottom {
  margin-top: 20px;
  bottom: 0;
}

.info-container svg {
  font-size: 2em;
  color: var(--third);
  margin-right: 40px;
}
</style>