<template>
  <div id="container" :style="containerStyle" :class="['container',(expandSidebar)?'open-sidebar':'']">
    <div class="sidebar global">
      <div :class="['sidebar-toggle-menu',(expandSidebar)?'open':'']" @click="expandSidebar=!expandSidebar">
        <font-awesome-icon icon="bars"/>
      </div>
      <ul class="sidebar-menu center">
        <li class="with-icon" v-for="(item, indx) in sidebar" :key="'global_sidebar_element_'+indx">
          <router-link :to="item.link">
            <font-awesome-icon :icon="item.icon"/>
            <span class="menu-title">{{ item.name[lang] }}</span></router-link>
        </li>
      </ul>
    </div>
    <div class="inner-container">
      <div class="header">
        <div class="logo-wrapper">
          <img
              :src="`${publicPath}assets/Spoard-02.png`"
              class="logo"/>
        </div>
        <div class="menu-list">
          <div class="form-input small-input">
            <input type="text" id="search_input" v-model="searchInput"
                   :placeholder="header.searchbar.placeholder[lang]"/>
          </div>
          <span @click="setLanguage('de')"
                :class="['menu-element', 'language-select', (lang==='de')?'active':'']">DE</span>
          <span>|</span>
          <span @click="setLanguage('en')"
                :class="['menu-element', 'language-select', (lang==='en')?'active':'']">EN</span>
          <span class="user-name menu-element">{{ user.firstname }} {{ user.lastname }}</span>
          <div class="menu-element">
            <img
                src="https://x4h4i2u3.rocketcdn.me/wp-content/uploads/2019/04/tinder-profilbild-schlechtes-Beispielfoto-Selfie-1-1024x542.jpg"
                class="profile-picture"/>
            <font-awesome-icon icon="chevron-down" class="no-margin-left profile-select"></font-awesome-icon>
          </div>
          <div class="mobile-menu-burger">
            <font-awesome-icon icon="bars"/>
          </div>
        </div>
      </div>
      <div class="main">
        <router-view :lang="lang" :user="user"></router-view>
      </div>
    </div>
    <div class="search-results" :style="searchResultStyle">
      <template v-if="searchInput.length > 0">
        <div class="sub-headline">Suchergebniss <font-awesome-icon @click="searchInput=''" class="dismiss-button" icon="times"></font-awesome-icon></div>
        <EventList id="searchbar_result_event" :enable-dismiss="true" :noimage="true" :lang="lang" :events="filteredSearchResults"></EventList>
      </template>
    </div>
  </div>
</template>

<script>
import {nav, header} from './global.json'
import {languageService} from "@/core/services/languageService";
import EventList from "@/components/view/EventList";

export default {
  name: "Main",
  components: {EventList},
  data() {
    return {
      expandSidebar: false,
      publicPath: process.env.BASE_URL,
      sidebar: nav,
      header: header,
      lang: "de",
      user: {
        firstname: "Max",
        lastname: "Mustermann"
      },
      searchInput: "",
      searchResults: [
        {
          thumbnail: "https://tennishead.net/wp-content/uploads/2021/04/10_Ariake_Tennis_Park_2020.jpeg",
          name: "Tennis Tunier am Waldplatz",
          date: "2021-06-20",
          time: "13:30",
          description: "Wir spielen. Du auch?",
          creator: "Frodo",
          sport: "Tennis",
          minPlayer: 2,
          maxPlayer: 4,
          currentPlayer: 3
        },
        {
          thumbnail: "https://st.hzcdn.com/simgs/pictures/landscapes/rhino-full-basketball-court-gallery-bay-area-rhino-court-img~c8511d1705ef74bd_4-4485-1-e69b526.jpg",
          name: "Basketball NBA Niveau",
          date: "2021-11-04",
          time: "12:00",
          description: "Nur für kuhle Kids.",
          creator: "Lamda",
          sport: "Basketball",
          minPlayer: 6,
          maxPlayer: 14,
          currentPlayer: 5
        }
      ]
    }
  },
  methods: {
    setLanguage(lang) {
      languageService.setSelectedLanguage(lang)
      this.lang = lang
    }
  },
  computed: {
    containerStyle() {
      let result = {}
      if (this.searchInput.length > 0) {
        result.gridTemplateColumns = "min-content 1fr min-content"
      }
      return result
    },
    searchResultStyle() {
      let result = {}
      if (this.searchInput.length > 0) {
        result.width = "400px"
        result.marginRight = "0"
      }
      return result
    },
    filteredSearchResults() {
      let result = []
      const val = this.searchInput.toLowerCase()
      if (val.length <= 0) {
        return result
      }
      for (const event of this.searchResults) {
        if (event.name.toLowerCase().includes(val)) {
          result.push(event)
        } else if (event.description.toLowerCase().includes(val)) {
          result.push(event)
        } else if (event.sport.toLowerCase().includes(val)) {
          result.push(event)
        }
      }
      return result
    }
  },
  mounted() {
    this.lang = languageService.getSelectedLanguage()
    let screenWidth = Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
    )
    if (screenWidth > 1300) {
      this.expandSidebar = true
    }
  }
}
</script>

<style>
.header {
  height: 80px;
  width: 100%;
}

.header .logo-wrapper {
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  float: left;
}

.header .logo {
  max-height: 40px;
}

.header .menu-list {
  position: relative;
  height: 80px;
  width: calc(100% - 200px);
  display: flex;
  align-items: center;
}

.header .small-input {
  width: 400px;
  float: left;
  flex-grow: 1;
  height: 20px;
  text-align: center;
  margin-left: 20px;
}

.header .small-input input {
  padding: 5px 15px;
  font-size: 1.2em;
  width: 400px;
  background: rgba(255, 255, 255, 0.97);
  border: 1px solid transparent;
}

.header .small-input input:focus {
  border: 1px solid var(--primary);
}

.header .menu-element {
  height: 100%;
  line-height: 80px;
  float: right;
  cursor: pointer;
  padding: 0 10px;
  color: var(--secondary-text-color);
  display: flex;
  align-items: center;
}

.header .menu-element.language-select {
  margin: 0 10px;
}


.header .menu-element.language-select.active {
  color: var(--primary);
}

.header .menu-element .no-margin-left {
  margin-left: 0;
  float: right;
}

.header .menu-element .profile-picture {
  border-radius: 50%;
  object-fit: cover;
  padding: 0;
  height: 60px;
  width: 60px;
  margin: 10px;
  float: right;
  border: 2px solid rgba(255, 255, 255, 0.6);
}

.header .menu-element:hover .profile-picture {
  border: 2px solid var(--primary);
}

.header .menu-element:hover {
  color: var(--primary);
}

.header .mobile-menu-burger {
  color: var(--primary);
  float: right;
  height: 100%;
  line-height: 80px;
  font-size: 1.4em;
  margin: 0;
  -webkit-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
  display: none;
}

.header .mobile-menu-burger.open {
  transform: rotate(90deg);
}

@media only screen and (max-width: 1100px) {
  .header .small-input {
    display: none;
  }

  .header .menu-list {
    align-items: center;
    justify-content: end;
  }

  .header .mobile-menu-burger {
    display: block;
  }

  .header .user-name {
    display: none;
  }

  .header .menu-element .profile-select {
    margin-right: 20px;
  }
}

.container {
  display: inline-grid;
  grid-template-columns: min-content 1fr min-content;
  grid-column-gap: 40px;
  width: 100%;
  height: 100%;
  margin: 0;
}

.search-results {
  width: 0;
  z-index: 10;
  margin-right: -40px;
  float: right;
  background: var(--primary);
  height: 100vh;
  padding: 10px;
  border-radius: 5px 0 0 5px;
  transition: width 1s;
  border-left: 2px solid var(--border-color);
}

.search-results .sub-headline {
  text-align: left;
  font-size: 20px;
  color: var(--text-color);
}

.search-results .sub-headline .dismiss-button {
  float: right;
  cursor: pointer;
  color: var(--text-color);
}

.container.open-sidebar .sidebar.global {
  width: 310px;
}

.inner-container {
  height: calc(100% - 80px);
  position: relative;
  transition: width 1s;
}

.main {
  width: 100%;
  max-width: 1300px;
  margin: 20px auto 0;
  height: calc(100% - 20px);
  position: relative;
  overflow: auto;
}

@media only screen and (max-width: 1100px) {
  .container {
    grid-template-columns: 1fr;
  }

  .container.open-sidebar {
    grid-template-columns: 1fr;
  }

  .container .sidebar.global {
    display: none;
  }

  .container .inner-container {
    width: 90%;
    height: 95%;
    margin: 2.5% 5%;
  }
}

@media only screen and (max-width: 1000px) {
  .container {
    grid-column-gap: 20px;
  }
}

/****
 **** SIDEBAR ****
 ****/

.sidebar {
  font-size: 1em;
  text-transform: uppercase;
}

.sidebar.global {
  display: flex;
  width: 60px;
  text-transform: none;
  flex-direction: column;
  align-items: flex-start;
  background: var(--primary);
  justify-content: flex-start;
  z-index: 5;
  transition: all 1s;
  border-radius: 0 5px 5px 0;
}

.container.open-sidebar .sidebar.global {
  width: 310px;
}

.sidebar.global .sidebar-toggle-menu {
  font-size: 1.2em;
  padding: 40px 0;
  margin-left: 20px;
  color: var(--text-color);
  cursor: pointer;
  -webkit-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
}

.sidebar.global .sidebar-toggle-menu.open {
  transform: rotate(90deg);
}

.sidebar.global .sidebar-toggle-menu:hover {
  color: var(--secondary);
}

.sidebar h1 {
  font-weight: 700;
}

.sidebar .sidebar-menu {
  list-style: none;
  padding: 0;
}

.sidebar.global .sidebar-menu {
}

.sidebar .sidebar-menu.center {
  text-align: center;
}

.sidebar .sidebar-menu li {
  padding: 15px 0;
  cursor: pointer;
}

.sidebar.global .sidebar-menu li {
  font-size: 1.2em;
  padding: 25px 0;
  color: var(--text-color);
  width: 60px;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  position: relative;
}

.sidebar .sidebar-menu li.with-icon svg {
  width: 40px;
  z-index: 6;
}

.sidebar.global .sidebar-menu li.with-icon .menu-title {
  position: absolute;
  left: 55px;
  padding: 25px 20px;
  z-index: 1;
  width: 250px;
  transform: translateX(-400px);
  transition: transform 1s;
  display: inline-block;
  top: 0;
  text-align: left;
  background: var(--primary);
}

.container.open-sidebar .sidebar.global .sidebar-menu li.with-icon .menu-title {
  transform: translateX(0);
  background: transparent;
}

.sidebar .sidebar-menu li.with-icon:hover svg, .sidebar .sidebar-menu li.with-icon.active svg {
  color: var(--third);
}

.sidebar.global .sidebar-menu li.with-icon:hover .menu-title {
  transform: translateX(0);
  background: var(--secondary);
}

.sidebar.global .sidebar-menu li.with-icon:hover svg, .sidebar.global .sidebar-menu li.with-icon a.active svg {
  color: var(--secondary);
}

.container.open-sidebar .sidebar.global .sidebar-menu li.with-icon:hover .menu-title, .sidebar.global .sidebar-menu li.with-icon a.active .menu-title {
  color: var(--secondary);
  border-right: 5px solid var(--secondary);
}

.container:not(.open-sidebar) .sidebar.global .sidebar-menu li.with-icon:hover, .sidebar.global .sidebar-menu li.with-icon a.active {
  border-right: 5px solid var(--secondary);
}

/****
 **** FORM INPUT ****
 ****/
.two-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
}

.two-columns .form-input {
  margin: 0 auto;
}

.form-input {
  width: 100%;
  position: relative;
  margin: 20px 0;
}

.form-input:not(:first-of-type) {
  margin: 0 0 20px;
}

.form-input.manual-margin {
  margin: 20px 0;
}

.form-input.small-input-100 {
  max-width: 100px;
}

.form-input.small-input-200 {
  max-width: 200px;
}

.form-input.small-input-300 {
  max-width: 300px;
}

.form-input .connect-button {
  padding: 15px 20px;
  cursor: pointer;
  background: transparent;
  border: 1px solid hsla(0, 0%, 54%, .1);
  border-radius: 10px;
  font-size: 1.5em;
  color: #333333;
  outline: none;
  font-family: 'Raleway', sans-serif;
}

.form-input .connect-button i {
  padding-right: 10px;
}

.form-input .connect-button:hover {
  border: 1px solid #333333;
}

.form-input .connect-button.twitter {
  color: #1DA1F2;
}

.form-input .connect-button.back {
  color: var(--warning);
  float: left;
}

.form-input .connect-button.facebook {
  color: #4267B2;
}

.form-input .connect-button.google {
  color: #DB4437;
}

.form-input .connect-button.twitch {
  color: #6441a5;
}

.form-input label {
  position: absolute;
  left: 10px;
  top: 10px;
  color: var(--gray-text-color);
  font-size: 0.9em;
}

.form-input input, .form-input textarea {
  width: 100%;
  padding: 30px 10px 10px;
  background: transparent;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  font-size: 1.5em;
  color: var(--secondary-text-color);
  outline: none;
  font-family: 'Raleway', sans-serif;
}

.form-input.form-input-image {
  position: relative;
  text-align: center;
  border: 1px solid var(--border-color);
}

.form-input.form-input-image img {
  width: 100%;
  max-height: 160px;
  object-fit: cover;
}

.form-input.form-input-image:hover img {
  filter: blur(2px);
  cursor: pointer;
}

.form-input.form-input-image:hover svg {
  display: block;
}

.form-input.form-input-image svg {
  position: absolute;
  font-size: 30px;
  display: none;
  cursor: pointer;
  color: var(--text-color);
  left: 45%;
  top: 40%;
}

.form-input.form-input-image label {
  top: -20px;
}

.form-input input:focus {
  border: 1px solid var(--secondary-text-color);
  outline: none;
}
</style>