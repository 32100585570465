import {createRouter, createWebHistory} from 'vue-router';

import Main from "@/components/Main";
import Dashboard from "@/components/dashboard/Dashboard";
import Settings from "@/components/settings/Settings";
import Calendar from "@/components/calendar/Calendar";
import CreateEvent from "@/components/event/CreateEvent";

const routes = [
    /**
    {path: '/login', component: Login},
    {path: '/login/:codeStatus', component: Login},
    {path: '/register', component: Register},
     **/
    {
        path: '/', component: Main,
        children: [
            {path: '/dashboard', component: Dashboard},
            {path: '/calendar', component: Calendar},
            {path: '/create-event', component: CreateEvent},
            {path: '/user-settings', component: Settings},
            {path: '/:pathMatch(.*)', redirect: '/dashboard'}

        ]
    },
    // otherwise redirect to home
    {path: '/:catchAll(.*)', redirect: '/dashboard'}
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach((to, from, next) => {
    /**
    const publicPages = ['/','/login','/login/success','/login/codeused','/login/invalid', '/login/exception', '/register'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('token');

    if (authRequired && !loggedIn) {
        return next('/login');
    }
**/
    next();
});

export default router