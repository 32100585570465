<template>
  <div>
    <div class="calendar">
      <div class="calendar-headline">
        <font-awesome-icon icon="calendar"></font-awesome-icon>
        <span class="month">{{ getCurrentCalendarMonth }} {{ calendarYear }}</span>
        <font-awesome-icon class="calendar-navigation" @click="nextMonth"
                           icon="chevron-right"></font-awesome-icon>
        <font-awesome-icon class="calendar-navigation" @click="lastMonth" icon="chevron-left"></font-awesome-icon>
      </div>
      <table class="calendar-table">
        <tr class="calendar-header">
          <th>{{ textContent.weekdays.mon[lang] }}</th>
          <th>{{ textContent.weekdays.tue[lang] }}</th>
          <th>{{ textContent.weekdays.wed[lang] }}</th>
          <th>{{ textContent.weekdays.thu[lang] }}</th>
          <th>{{ textContent.weekdays.fri[lang] }}</th>
          <th>{{ textContent.weekdays.sat[lang] }}</th>
          <th>{{ textContent.weekdays.sun[lang] }}</th>
        </tr>
        <tr class="calendar-row" v-for="(item, indx) in getCalendar" :key="'dashboard_calendar_row_'+indx">
          <td :class="[(date.isToday)?'is-today':'',(date.events && date.events.length > 0)?'event':'']"
              v-for="(date, calindx) in item" :key="'dashboard_calendar_row_'+indx+'_cell_'+calindx">
            {{ (date.active) ? date.day : '' }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import {textContent} from "@/components/calendar/calendar.json";

export default {
  name: "Calendar",
  props: ["lang"],
  data() {
    return {
      events: [
        {
          thumbnail: "https://tennishead.net/wp-content/uploads/2021/04/10_Ariake_Tennis_Park_2020.jpeg",
          name: "Tennis Tunier am Waldplatz",
          date: "2021-06-05",
          time: "15:00",
          description: "Wir spielen. Du auch?",
          creator: "Frodo",
          sport: "Tennis",
          minPlayer: 2,
          maxPlayer: 4,
          currentPlayer: 3
        },
        {
          thumbnail: "https://tennishead.net/wp-content/uploads/2021/04/10_Ariake_Tennis_Park_2020.jpeg",
          name: "Tennis Tunier am Waldplatz",
          date: "2021-06-20",
          time: "13:30",
          description: "Wir spielen. Du auch?",
          creator: "Frodo",
          sport: "Tennis",
          minPlayer: 2,
          maxPlayer: 4,
          currentPlayer: 3
        },
        {
          thumbnail: "https://st.hzcdn.com/simgs/pictures/landscapes/rhino-full-basketball-court-gallery-bay-area-rhino-court-img~c8511d1705ef74bd_4-4485-1-e69b526.jpg",
          name: "Basketball NBA Niveau",
          date: "2021-11-04",
          time: "12:00",
          description: "Nur für kuhle Kids.",
          creator: "Lamda",
          sport: "Basketball",
          minPlayer: 6,
          maxPlayer: 14,
          currentPlayer: 5
        }
      ],
      textContent: textContent,
      calendarYear: 2021,
      calendarMonth: 4
    }
  },
  mounted() {
    const today = new Date()
    this.calendarYear = today.getFullYear()
    this.calendarMonth = today.getMonth() + 1
  },
  methods: {
    nextMonth() {
      if (this.calendarMonth > 11) {
        this.calendarMonth = 1
        this.calendarYear++;
      } else {
        this.calendarMonth++
      }
    },
    lastMonth() {
      if (this.calendarMonth <= 1) {
        this.calendarMonth = 12
        this.calendarYear--;
      } else {
        this.calendarMonth--
      }
    }
  },
  computed: {
    getCurrentCalendarMonth() {
      return this.textContent.months[this.calendarMonth.toString()][this.lang]
    },
    getCalendar() {
      let firstDay = new Date(this.calendarYear, this.calendarMonth + 1, 1)
      let lastDay = new Date(this.calendarYear, this.calendarMonth + 2, 0)
      let weeks = []
      let startCounter = 1
      const today = new Date()
      for (let i = 1; i <= 6; i++) {
        let tmpWeekArray = []
        for (let j = 0; j < 7; j++) {
          if (i === 1 && firstDay.getDay() >= j) {
            tmpWeekArray.push({
              active: false
            })
          } else if (startCounter > lastDay.getDate()) {
            tmpWeekArray.push({
              active: false
            })
          } else {
            let istoday = true
            if (today.getFullYear() !== this.calendarYear) {
              istoday = false
            } else if (today.getMonth() + 1 !== this.calendarMonth) {
              istoday = false
            } else if (today.getDate() !== startCounter) {
              istoday = false
            }
            let events = []
            for (const event of this.events) {
              const eventdate = event.date.split("-")
              if (parseInt(eventdate[0]) === this.calendarYear && parseInt(eventdate[1]) === this.calendarMonth && parseInt(eventdate[2]) === startCounter) {
                events.push(event)
              }
            }
            tmpWeekArray.push({
              active: true,
              day: startCounter++,
              isToday: istoday,
              events: events
            })
          }
        }
        weeks.push(tmpWeekArray)
      }
      return weeks
    }
  }
}
</script>

<style scoped>
.calendar {
  background-color: var(--secondary-container-background);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin: 10px 0;
  padding: 25px;
}

.calendar .calendar-headline {
  font-size: 1.4em;
  color: var(--primary);
  user-select: none;
}

.calendar .calendar-headline .month {
  margin-left: 25px;
}

.calendar .calendar-headline .calendar-navigation {
  float: right;
  color: var(--secondary-text-color);
  margin: 0 10px;
  cursor: pointer;
}

.calendar .calendar-table {
  width: 100%;
  margin-top: 25px;
  user-select: none;
  color: var(--gray-text-color);
}

.calendar .calendar-table .calendar-header th {
  height: 50px;
  color: var(--text-color);
  background: var(--primary);
}

.calendar .calendar-table .calendar-row {
  text-align: center;
  margin-top: 10px;
}

.calendar .calendar-table .calendar-row td {
  padding: 10px 0;
  height: 60px;
  width: 60px;
  border-radius: 5px;
}

.calendar .calendar-table .calendar-row td.event {
  background-color: var(--success);
  color: var(--text-color);
}

.calendar .calendar-table .calendar-row td.is-today {
  border: 2px solid var(--third);
  color: var(--third);
}

.calendar .calendar-table .calendar-row td.is-today.event {
  /**background-image: -webkit-linear-gradient(30deg, var(--primary) 50%, var(--success) 50%);**/
  border: 2px solid var(--third);
  background-color: var(--success);
  color: var(--text-color);
}
</style>