<template>
  <div class="event-list">
    <h1>{{ headline }}</h1>
    <div :class="['event',(!!noimage)?'no-image':'']" v-for="(item, indx) in events" :key="'event_view_'+id+'_event_'+indx">
      <img v-if="!noimage" class="event-image" :src="item.thumbnail"/>
      <div class="event-data">
            <span class="headline">{{ item.name }} <span class='time'
                                                         v-html="item.date + ' - ' + formatTime(item.time)"></span></span>
        <span class="description">{{ item.description }}</span>
        <span class="filler"></span>
        <span class="createdby" v-html="getCreatedBy(item)"></span>
        <div class="details">
          <span class="tag">{{ item.sport }}</span>
          <span class="tag">{{ getTagPlayer(item) }}</span>
          <span :class="['tag',(item.currentPlayer >= item.minPlayer)?'active':'secondary']">{{
              getTagPlayerCurrent(item)
            }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {eventList} from "@/components/view/view.json";
import {languageService} from "@/core/services/languageService";

export default {
  name: "EventList",
  props: ['events', 'id','lang','headline','noimage','enableDismiss'],
  data() {
    return {
      textContent: eventList
    }
  },
  methods: {
    getCreatedBy(event) {
      return languageService.fillInParameter(this.textContent.createdby[this.lang], event)
    },
    getTagPlayer(event) {
      return languageService.fillInParameter(this.textContent.tagPlayer[this.lang], event)
    },
    getTagPlayerCurrent(event) {
      return languageService.fillInParameter(this.textContent.tagCurrentPlayer[this.lang], event)
    },
    formatTime(time) {
      let times = time.split(":")
      let result = times[0] + "<sup>"
      if (times.length > 1) {
        result += times[1]
      } else {
        result += "00"
      }
      result += "</sub>"
      return result
    }
  }
}
</script>

<style scoped>
.event-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
}

.event-list h1 {
  padding-top: 0;
  margin-top: 0;
}

.event-list .event {
  display: grid;
  grid-template-columns: 250px 1fr;
  text-align: left;
  height: 150px;
  width: 100%;
  background-color: var(--secondary-container-background);
  border: 1px solid var(--border-color);
  border-radius: 5px;
}

.event-list .event.no-image {
  grid-template-columns: 1fr;
  padding: 5px;
  font-size: 0.8em;
}

.event-list .event:not(:first-of-type) {
  margin-top: 15px;
}

.event-list .event img {
  width: 225px;
  height: 150px;
  object-fit: cover;
  border: 2px solid var(--border-color);
  border-radius: 5px;
}

.event-list .event .event-data {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.event-list .event .event-data > span {
  display: block;
  padding: 5px 0;
  font-weight: 300;
}

.event-list .event .event-data .headline {
  font-size: 1.2em;
  padding-top: 10px;
}

.event-list .event .event-data .headline .time {
  float: right;
  padding-right: 20px;
}

.event-list .event .event-data .description {
  font-size: 1em;
  color: var(--gray-text-color);
  text-overflow: ellipsis;
}

.event-list .event .event-data .filler {
  flex-grow: 1;
}

.event-list .event .event-data .createdby {
  color: var(--gray-text-color);
  font-weight: lighter;
}

.event-list .event .event-data .details {
  height: 40px;
  padding: 5px 0;
}

.event-list .event .event-data .details .tag {
  font-size: 0.9em;
  padding: 5px 10px;
  user-select: none;
  border-radius: 5px;
  background-color: var(--primary);
  color: var(--text-color);
}

.event-list .event .event-data .details .tag.secondary {
  background-color: var(--secondary);
}

.event-list .event .event-data .details .tag.active {
  background-color: var(--success);
}

.event-list .event .event-data .details .tag:not(:first-child) {
  margin-left: 10px;
}

@media only screen and (max-width: 1550px) {
  .event-list {
    font-size: 0.8em;
  }

  .event-list .event {
    grid-template-columns: 1fr;
    padding: 0 25px;
  }

  .event-list .event img {
    display: none;
  }
}
</style>